import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import dracula from "prism-react-renderer/themes/dracula";
import shadesOfPurple from "prism-react-renderer/themes/shadesOfPurple";

export default ({ children, className, mode }) => {
  const language = className.replace(/language-/, "");
  return (
    <Highlight
      {...defaultProps}
      theme={mode === "light" ? shadesOfPurple : dracula}
      code={children.trim()}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={`${className} text-left overflow-scroll`}
          style={{ ...style, padding: "10px" }}
        >
          {tokens.map((line, i) => (
            <div
              key={i}
              className="table-row"
              {...getLineProps({ line, key: i })}
            >
              <span className="table-cell text-right pr-4 select-none opacity-50 ">
                {i + 1}
              </span>
              <div className="table-cell">
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
